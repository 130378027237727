import React from "react"

import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react"
import Section from "gatsby-theme-wild-child/src/components/Section"

function TermsOfUsePage() {
  return (
    <Section mt={8} minH="100vh">
      <SimpleGrid columns={2} spacing={10} mb={10}>
        <Box display={"flex"} alignItems={"center"}>
          <Heading fontSize="2xl" color="red.full" p={0}>
            Terms of Use
          </Heading>
        </Box>
      </SimpleGrid>
      <VStack spacing={6} maxW="1200px" w="full" align="start">
        <Text fontSize="lg" color="gray.700" mb={8}>
          Welcome to asktaxgpt.ae, a service provided by Virtuzone ("Virtuzone",
          "we", "us", or "our"). By using our service, you agree to these Terms
          of Use and our Disclaimer.
        </Text>
        <Text fontSize="lg" color="gray.700" mb={8}>
          <strong>1. Acceptance of Terms</strong>
        </Text>
        <Text fontSize="lg" color="gray.700" mb={8}>
          By using our website and services, you agree to these Terms of Use,
          our Disclaimer, and any modifications that may be made to them over
          time. If you do not agree to these Terms of Use and our Disclaimer,
          please do not use our website or services.
        </Text>
        <Text fontSize="lg" color="gray.700" mb={8}>
          <strong>2. Use of the Service</strong>
        </Text>
        <Text fontSize="lg" color="gray.700" mb={8}>
          TaxGPT is an automated tool designed to provide general tax
          information sourced from various government reference websites. The
          information provided is not intended to replace professional tax
          advice. Always consult with a tax professional for your specific
          circumstances.
        </Text>
        <Text fontSize="lg" color="gray.700" mb={8}>
          <strong>3. Limitations of Liability</strong>
        </Text>
        <Text fontSize="lg" color="gray.700" mb={8}>
          Virtuzone and its affiliates, partners, officers, employees, agents,
          contractors, and licensors shall not be liable for any direct,
          indirect, incidental, consequential, special, exemplary, or punitive
          damages resulting from your access to or use of, or inability to
          access or use, the service or any content on the service.
        </Text>
        <Text fontSize="lg" color="gray.700" mb={8}>
          <strong>4. Changes to the Terms of Use</strong>
        </Text>
        <Text fontSize="lg" color="gray.700" mb={8}>
          Virtuzone reserves the right to modify these Terms of Use at any time.
          We will post any changes on this page, so please check back
          periodically. Your continued use of our services after any change in
          these Terms of Use or our Disclaimer will constitute your acceptance
          of such change.
        </Text>
        <Text fontSize="lg" color="gray.700" mb={8}>
          <strong>5. Governing Law</strong>
        </Text>
        <Text fontSize="lg" color="gray.700" mb={8}>
          These Terms of Use and any dispute or claim arising out of or in
          connection with them or their subject matter or formation shall be
          governed by and construed in accordance with the law of the United
          Arab Emirates.
        </Text>
        <Text fontSize="lg" color="gray.700" mb={8}>
          <strong>6. Contact Information</strong>
        </Text>
        <Text fontSize="lg" color="gray.700" mb={8}>
          If you have any questions about these Terms of Use or our Disclaimer,
          please contact us at +97144578200.
        </Text>
      </VStack>
    </Section>
  )
}

export default TermsOfUsePage
